var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.no_activity,
            expression: "!no_activity",
          },
        ],
        staticClass: "lucky-wheel df fdc alc",
        style: {
          background: _vm.seaType
            ? "url(" +
              _vm.backdrop_img +
              ") no-repeat local center top / 100% auto"
            : "url(" + _vm.backdrop_img + ")",
        },
      },
      [
        !_vm.seaType ? _c("div", { staticClass: "title" }) : _vm._e(),
        _vm.hide_join_num == 1 && !_vm.seaLottery
          ? _c("p", { staticClass: "f28" }, [
              _vm._v("\n      已有"),
              _c("span", { staticClass: "f36 f500" }, [
                _vm._v(_vm._s(_vm.add_num)),
              ]),
              _vm._v("人参与\n    "),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "wheel-box" },
          [
            _c("BigWheel", {
              ref: "luckyWheel",
              attrs: {
                prizeList: _vm.prize_list,
                goBtnImg: _vm.lottery_pic,
                colors: _vm.colors,
                fontColor: "#FF6600",
                strMaxLength: 14,
                strLineLength: 6,
                strKey: "item_name",
              },
              on: { "go-click": _vm.luckDraw, "on-over": _vm.drawOver },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "lucky-bottom flex-center" }, [
          !_vm.seaLottery
            ? _c("div", { staticClass: "times" }, [
                _vm._v("您还有 "),
                _c("span", { staticClass: "f36 f500" }, [
                  _vm._v(_vm._s(_vm.surplus_num)),
                ]),
                _vm._v(" 次机会,\n        剩余免费次数: "),
                _c("span", { staticClass: "f36 f500" }, [
                  _vm._v(_vm._s(_vm.now_free_num)),
                ]),
                _vm._v(" ,\n        每次消耗"),
                _c("span", { staticClass: "f36 f500" }, [
                  _vm._v(_vm._s(_vm.pay_num)),
                ]),
                _vm.businessType == 1
                  ? _c("span", [_vm._v("\n        元\n        ")])
                  : _vm._e(),
                _vm.businessType == 2
                  ? _c("span", [_vm._v("\n        积分\n        ")])
                  : _vm._e(),
              ])
            : _c("div", { staticClass: "times sea-times" }, [
                _vm._v(
                  "\n        还有免费次数" +
                    _vm._s(_vm.now_free_num) +
                    "\n      "
                ),
              ]),
          _c(
            "div",
            { staticClass: "lucky-btn", on: { click: _vm.toRafflePrize } },
            [_vm._v("\n        我的奖品\n      ")]
          ),
          _c(
            "div",
            {
              staticClass: "look df fdr alc jc-sb",
              on: {
                click: function ($event) {
                  _vm.show_rules = true
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.seaLottery ? "活动规则" : "查看游戏规则") +
                  "\n        "
              ),
              _c("div", { staticClass: "triangle" }),
            ]
          ),
        ]),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toast_control,
              expression: "toast_control",
            },
          ],
          staticClass: "toast-mask",
          on: {
            click: function ($event) {
              _vm.toast_control = false
            },
          },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.toast_control,
                expression: "toast_control",
              },
            ],
            staticClass: "toast",
          },
          [
            _c("div", { staticClass: "toast-container df fdc alc jc-sb" }, [
              _c("div", { staticClass: "toast-circle" }, [
                _c("img", { attrs: { src: _vm.award_img, alt: "" } }),
              ]),
              _c("div", { staticClass: "toast-bottom df fdc alc" }, [
                _c("div", [
                  _vm._v("恭喜您抽到“" + _vm._s(_vm.award_name) + "”，可在 "),
                  _c("br"),
                  _vm._v("我的奖品中查看"),
                ]),
                !_vm.receive_method
                  ? _c(
                      "div",
                      {
                        staticClass: "toast-btn",
                        on: {
                          click: function ($event) {
                            _vm.toast_control = false
                          },
                        },
                      },
                      [_vm._v("好的")]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "toast-btn",
                        on: { click: _vm.selectAddress },
                      },
                      [_vm._v("立即领取")]
                    ),
              ]),
            ]),
          ]
        ),
        _vm.show_rules ? _c("div", { staticClass: "toast-mask" }) : _vm._e(),
        _c("transition", { attrs: { name: "zoom" } }, [
          _vm.show_rules
            ? _c("div", { staticClass: "alert-container rules-alert" }, [
                _c("div", { staticClass: "alert-content" }, [
                  _c("div", { staticClass: "rules-container flex-center" }, [
                    _c("div", { staticClass: "rules-screen" }, [
                      _c("div", { staticClass: "rules-box" }, [
                        _c("div", { staticClass: "top-title" }, [
                          _c("span", { staticClass: "dot" }),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(
                              _vm._s(_vm.seaLottery ? "活动规则" : "游戏规则")
                            ),
                          ]),
                          _c("span", { staticClass: "dot" }),
                        ]),
                        !_vm.seaType
                          ? _c("h3", { staticClass: "rules-title" }, [
                              _vm._v("幸运大转盘"),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "active-time" }, [
                          _c("div", [_vm._v("活动时间：")]),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDateTime")(this.start_time)
                                ) +
                                  " ~ " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(this.end_time)
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "active-time" }, [
                          _c("div", [_vm._v("活动奖品：")]),
                          _c(
                            "div",
                            _vm._l(_vm.active_list, function (item, index) {
                              return _c("div", { key: item.id }, [
                                _vm._v(
                                  "\n                      奖项" +
                                    _vm._s(_vm.chineseNumber[index]) +
                                    "：" +
                                    _vm._s(item.item_name) +
                                    "\n                    "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "active-time active-explain" },
                          [_vm._v("活动及兑奖说明")]
                        ),
                        _c("article", {
                          staticClass: "rules-content",
                          domProps: { innerHTML: _vm._s(_vm.rule) },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "btn close-btn",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeRules.apply(null, arguments)
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("yd-keyboard", {
          ref: "keyboard",
          attrs: {
            callback: _vm.checkPwd,
            disorder: "",
            "input-text": "输入支付密码",
            title: "",
          },
          model: {
            value: _vm.show_keyboard,
            callback: function ($$v) {
              _vm.show_keyboard = $$v
            },
            expression: "show_keyboard",
          },
        }),
        _c("ConfirmAlert", {
          attrs: {
            value: _vm.no_pwd,
            is_delete: false,
            confirmText: "抱歉，您还没有设置支付密码",
            confirmBtnText: "去设置",
          },
          on: {
            cancel: function ($event) {
              _vm.no_pwd = false
            },
            confirm: _vm.confirmPwd,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.no_activity,
            expression: "no_activity",
          },
        ],
        staticClass: "lucky-wheel df fdc alc",
      },
      [
        _c("div", { staticClass: "empty-box df fdc alc" }, [
          _c("img", { attrs: { src: _vm.emp_img, alt: "emp_img" } }),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.seaType ? "活动未开始" : "大转盘活动未开始，暂不能参加！"
              ) +
              "\n    "
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }