<template>
  <div>
    <div v-show="!no_activity" class="lucky-wheel df fdc alc"  v-bind:style="{background: seaType ? 'url(' + backdrop_img +') no-repeat local center top / 100% auto' : 'url(' + backdrop_img +')'}">
      <div v-if="!seaType" class="title"></div>
      <p class="f28" v-if="hide_join_num==1 && !seaLottery  ">
        已有<span class="f36 f500">{{add_num}}</span>人参与
      </p>
      <!--大转盘-->
      <div class="wheel-box">
        <BigWheel
            ref="luckyWheel"
            :prizeList='prize_list'
            :goBtnImg="lottery_pic"
            :colors='colors'
            fontColor='#FF6600'
            :strMaxLength='14'
            :strLineLength='6'
            strKey='item_name'
            @go-click="luckDraw"
            @on-over="drawOver">
        </BigWheel>
      </div>
      <div class="lucky-bottom flex-center">
        <!--剩余抽奖次数-->
        <div v-if="!seaLottery" class="times">您还有 <span class="f36 f500">{{surplus_num}}</span> 次机会,
          剩余免费次数: <span class="f36 f500">{{now_free_num}}</span> ,
          每次消耗<span class="f36 f500">{{pay_num}}</span>
          <span v-if="businessType == 1">
          元
          </span>
          <span v-if="businessType == 2">
          积分
          </span>
        </div>
        <div v-else class="times sea-times">
          还有免费次数{{now_free_num}}
        </div>
        <!--<div class="times"></div>-->
        <div class="lucky-btn" @click="toRafflePrize">
          我的奖品
        </div>
        <div class="look df fdr alc jc-sb" @click="show_rules=true">
          {{seaLottery ? '活动规则' : '查看游戏规则'}}
          <div class="triangle"></div>
        </div>
      </div>
      <!--中奖弹窗-->
      <div class="toast-mask" v-show="toast_control" @click="toast_control=false"></div>
      <div class="toast" v-show="toast_control">
        <div class="toast-container df fdc alc jc-sb">
          <div class="toast-circle">
            <img :src="award_img" alt="">
          </div>
          <div class="toast-bottom df fdc alc">
            <div>恭喜您抽到“{{award_name}}”，可在 <br/>我的奖品中查看</div>
            <div v-if="!receive_method" class="toast-btn" @click="toast_control=false">好的</div>
            <div v-else class="toast-btn" @click="selectAddress">立即领取</div>
          </div>

        </div>
      </div>
      <!--游戏规则-->
      <div class="toast-mask" v-if="show_rules"></div>
      <transition name="zoom">
        <!-- S 游戏规则弹窗 -->
        <div class="alert-container rules-alert" v-if="show_rules">
          <div class="alert-content">
            <div class="rules-container flex-center">
              <div class="rules-screen">
                <div class="rules-box">
                  <div class="top-title">
                    <span class="dot"></span>
                    <span class="txt">{{seaLottery ? '活动规则' : '游戏规则'}}</span>
                    <span class="dot"></span>
                  </div>
                  <h3 v-if="!seaType" class="rules-title">幸运大转盘</h3>
                  <!-- 活动时间 -->
                  <div class="active-time">
                    <div>活动时间：</div>
                    <div>
                      <span>{{this.start_time | formatDateTime}} ~ {{this.end_time | formatDateTime}}</span>
                    </div>
                  </div>
                  <!-- 活动奖品 -->
                  <div class="active-time">
                    <div>活动奖品：</div>
                    <div>
                      <div v-for="(item, index) in active_list" :key="item.id">
                        奖项{{chineseNumber[index]}}：{{item.item_name}}
                      </div>
                    </div>
                  </div>
                  <div class="active-time active-explain">活动及兑奖说明</div>
                  <article class="rules-content" v-html="rule"></article>
                </div>
              </div>
            </div>
            <div class="btn close-btn" @click.stop="closeRules"></div>
          </div>
        </div>
        <!-- E 游戏规则弹窗 -->
      </transition>
      <!--安全键盘-->
      <yd-keyboard v-model="show_keyboard" :callback="checkPwd" disorder ref="keyboard" input-text="输入支付密码" title></yd-keyboard>
      <!--没有支付密码弹窗-->
      <ConfirmAlert
        :value="no_pwd"
        :is_delete="false"
        confirmText="抱歉，您还没有设置支付密码"
        confirmBtnText="去设置"
        @cancel="no_pwd=false"
        @confirm="confirmPwd"
      ></ConfirmAlert>
    </div>
    <div v-show="no_activity"  class="lucky-wheel df fdc alc">
      <div class="empty-box df fdc alc">
        <img :src="emp_img" alt="emp_img" />
        {{seaType ? '活动未开始' : '大转盘活动未开始，暂不能参加！'}}
      </div>
    </div>
  </div>
</template>

<script>
import {getTurntableInfo, luckDraw, receiveAward, drawHaiyuansu, getActivityDetail} from "@/services/my";
import BigWheel from '../../../components/wheel/BigWheel'
import ConfirmAlert from "@/components/common/ConfirmAlert";
import { mapState } from "vuex";
import md5 from "js-md5";
import { customAccount } from "@/utils/custom";

export default {
  name: "LuckyWheel",
  data() {
    return {
      colors: ["#fceab7", "#ffffff"],
      targetIndex: 0,
      turntable_id: '',//大转盘id
      surplus_num: 0, //抽奖次数
      prize_list: [], //奖品列表
      toast_control: false, //抽奖结果弹出框控制器
      hasPrize: false, //是否中奖
      index: 0,
      show_rules: false,//游戏规则弹窗
      rule: '',//游戏规则
      is_thank: '',//是否中奖
      award_name: '',//奖品名称
      award_img: '',//奖品图片
      hide_join_num:'',//隐藏参加人数
      add_num:'',//参与人数
      freeware_time:'',//免费抽奖次数
      has_join_num:'',//当前已抽奖次数
      pay_num:'',//每次抽奖消耗
      now_free_num:'',//剩余免费次数
      show_keyboard: false, //支付密码键盘
      has_input_password: 0, // 是否输入过支付密码, 0否1是
      no_pwd: false, //是否设置支付密码
      start_time: '', //  活动开始时间
      end_time: '',  //  活动结束时间
      chineseNumber: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      active_list: [],  //  活动奖品
      backdrop_img: '',  // 背景图片
      lottery_pic: '',  // 指针图片
      emp_img: require("@/assets/img_location_blank@3x.png"),
      no_activity: true,  // 活动是否开启
      receive_method: Number,
      good_id: Number,  //奖品id
      item_type: Number,
      seaLottery: false,  //  抽奖商城
      seaType: '',
      turn_id: ''
    };
  },
    computed: {
      ...mapState({
            businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
            pay_password: state => state.userInfo.pay_password, //支付密码
            has_pay_password: state => !!state.userInfo.pay_password, //是否设置支付密码
        })
    },
  components: {
    BigWheel,
    ConfirmAlert
  },
  created() {
    this.seaType = this.$route.query.type || ''
    document.title = ''
  },
  mounted() {
    let has_input_password = localStorage.getItem('has_input_password');
    console.log(typeof has_input_password);
    this.has_input_password = has_input_password || 0;
    this.getTurntableInfo()
    this.isSeaLottery()
  },
  methods: {
    drawOver() {
      if (this.is_thank === 1) {
        this.$dialog.toast({
          mes: '很遗憾，离奖品就差一步了',
          icon: 'error',
        });
        this.getTurntableNum()
      } else {
        this.toast_control = true;
        this.getTurntableNum()

      }


    },
    async luckDraw(event) {
      console.log("TCL: go -> event", event)
      let can_draw = false; // 是否可以抽奖
      let {surplus_num, now_free_num, businessType, has_pay_password, has_input_password} = this;
      if(!this.turntable_id)
        return
      // 如果需要消耗余额或积分
      if (businessType == 1 || businessType == 2) {
        if (surplus_num && now_free_num == 0) {
          // 是否有支付密码
          if (has_pay_password && has_input_password == 1) {
            console.log('有支付密码且输过了')
            can_draw = true;
          } else if (has_pay_password && has_input_password == 0) {
            console.log('有密码没输过');
            //输入支付密码
			      this.toPayPwd();
          } else {
            console.log('没密码');
            this.no_pwd = true;
          }
        } else {
          can_draw = true;
        }
      } else {
        can_draw = true;
      }

      console.log({can_draw})

      if (!can_draw) {
        this.$refs.luckyWheel.rotateFunc(-1);
        return;
      }

      try {
        const params = {
          turntable_id: this.turntable_id,
          type: this.seaType
        }
        const res = this.seaType ? await drawHaiyuansu(params)  : await luckDraw(this.turntable_id);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.good_id = data.draw_log_id
          this.is_thank = data.is_thank
          this.award_name = data.item_name
          this.item_type = data.item_type
          this.award_img = data.item_img
          this.receive_method = data.receive_method
          setTimeout(() => {
            let i = this.prize_list.length;
            while (i--) {
              if (this.prize_list[i].id === data.id) {
                this.targetIndex = i
                console.log("TCL: go -> this.targetIndex", this.targetIndex)
                this.$refs.luckyWheel.rotateFunc(this.targetIndex)
                return;
              }
            }

          }, 200);
        } else {
          this.$refs.luckyWheel.rotateFunc(-1);
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
          });
        }
      } catch (e) {
        console.log(e);
        this.$dialog.toast({
          mes: '网络繁忙，稍后再试',
          icon: 'error',
          timeout: 1500
        });
      }

    },
    //去设置支付密码
    confirmPwd() {
      this.$router.push({
        name: "verify_phone",
        query: {
          function_type: 1
        }
      });
    },
    //支付密码
    toPayPwd() {
      this.show_keyboard = true;
    },
    //  验证支付密码
    checkPwd(val) {
      let {pay_password} = this;
      console.log("输入的密码是：" + val + " - 无序");
      console.log('md5后：', md5(md5(val)));
      this.$dialog.loading.open('验证支付密码');
      if (pay_password == md5(md5(val))) {
        console.log('验证通过');
        this.has_input_password = 1;
      } else {
        this.has_input_password = 0;
        this.$refs.keyboard.$emit('ydui.keyboard.error', '对不起，您的支付密码不正确，请重新输入。');
      }
      this.$dialog.loading.close();
      localStorage.setItem('has_input_password', this.has_input_password);
      if (this.has_input_password == 1) {
        this.show_keyboard = false;
        this.luckDraw();
      }
    },
    /**
     * 获取大转盘信息
     * @returns {Promise<void>}
     */
    async getTurntableInfo() {
      try {
        let params = {
          type: this.seaType || ''
        }
        const res = this.seaType ? await getActivityDetail(params) : await getTurntableInfo();
        if (res.code === 0) {
          this.no_activity = false
          const data = res.data;
          console.log({data});
          document.title = data.turnplate_name
          this.turn_id = data.id
          this.backdrop_img = data.backdrop_img
          this.lottery_pic = data.lottery_pic
          this.prize_list = data.item_list
          if (Number(this.seaType) === 2) {
            this.prize_list.forEach((item, index) => {
              if(item.item_name == '谢谢参与') {
                this.prize_list.splice(index, 1)
              }
            })
          }
          console.log('list2', this.prize_list)
          this.surplus_num = data.surplus_num
          this.rule = data.rule.replace(/(\r\n|\n|\r)/gm, "<br />") || ''
          this.turntable_id = data.id
          this.hide_join_num = data.hide_join_num
          this.add_num = Number(data.add_num)+Number(data.join_num)
          this.freeware_time = data.freeware_time
          this.has_join_num = Number(data.draw_num)-Number(data.surplus_num)
          this.pay_num = (data.pay_num)
          // this.now_free_num = (data.freeware_time-this.has_join_num) > 0 ? data.freeware_time-this.has_join_num : 0
          this.now_free_num = this.seaType ? data.surplus_num : data.free_surplus_num
          this.start_time =  data.start_time
          this.end_time =  data.end_time
          this.active_list = this.prize_list.filter((item,index) => {
            return item.item_name !== '谢谢参与'
          })
        } else if (res.code === -1) {
          if (this.seaType == 1) {
            document.title = '月月抽'
          } else if (this.seaType == 2) {
            document.title = '转存'
          } else {
            document.title = '超级大转盘'
          }
          this.no_activity = true
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
          });
          // setTimeout(() => {
          //   this.$router.go(-1);
          // }, 1500);
        }
      } catch (e) {
        this.$dialog.toast({
          mes: '网络繁忙，稍后再试',
          icon: 'error',
          timeout: 1500
        });
      }
    },
    async getTurntableNum() {
      try {
        let params = {
          type: this.seaType || ''
        }
        const res = this.seaType ? await getActivityDetail(params) : await getTurntableInfo()
        if (res.code === 0) {
          const data = res.data;
          this.surplus_num = data.surplus_num
          this.has_join_num = Number(data.draw_num)-Number(data.surplus_num)
          // this.now_free_num = (data.freeware_time-this.has_join_num) > 0 ? data.freeware_time-this.has_join_num : 0
          this.now_free_num = this.seaType ? data.surplus_num : data.free_surplus_num
        }
      } catch (e) {
        console.log(e);
      }
    },
    //关闭中奖弹窗
    close_toast() {
      this.toast_control = false;
    },
    //关闭规则弹窗
    closeRules() {
      this.show_rules = false
    },
    toRafflePrize() {
      if (this.seaType) {
         this.$router.push({
          name: 'raffle_prize',
          query: {
            type: this.seaType,
            id: this.turn_id
          }
        })
      } else {
        this.$router.push({
          name: 'raffle_prize',
        })
      }
    },

    // 跳到地址页面
    selectAddress() {
      if (this.seaLottery) {
        this.$router.push({
          name: 'receive_award',
          query: {
            item_id: this.good_id
          }
        });
        return
      }
      if (this.item_type) {
        this.receiveAward()
      } else {
        this.$router.push({
          name: 'address',
          query: {
            name: 'turntable',
            id: this.good_id
          }
        });
      }
      // if (this.item_type) {
      //   if ( !this.seaLottery ) {
      //     this.receiveAward()
      //   } else {
      //       this.$router.push({
      //         name: 'receive_award',
      //         query: {
      //           name: 'receive_award',
      //           item_id: this.good_id
      //         }
      //       });
      //   }
      // } else {
      //   this.$router.push({
      //     name: 'address',
      //     query: {
      //       name: 'turntable',
      //       id: this.good_id
      //     }
      //   });
      // }
    },

    async receiveAward(item) {
      try {
        let params = {
          draw_log_id: this.good_id || 0
        }
        const res = await receiveAward(params)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "奖品领取成功，请注意查收",
            timeout: 1500
          });
        } else {
          this.$dialog.toast({
            mes: res.msg,
            icon: 'error',
            timeout: 1500
          });
        }
        this.toast_control = false;
      } catch(e) {}
    },

     //  是否是海元素抽奖商城
    isSeaLottery() {
      if ( customAccount('VUE_APP_SEA_LOTTERY') ) {
        this.seaLottery = true
        console.log('12', this.seaLottery)
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem('has_input_password');
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.lucky-wheel {
  height: 100vh;
  // .bis("../../../assets/wheel/bg_slyderadventures");

  .empty-box {
    background: @color-white;
    height: 100vh;
    width: 100vw;

    img {
      .wh(3.64rem, 2.58rem);
      margin-top: 1.76rem;
      margin-bottom: 0.74rem;
    }

    .sc(0.28rem, @color-light-grey);
  }

  .title {
    .wh(3.98rem, 2.2rem);
    margin-top: 3.3%;
    .bis("../../../assets/wheel/bg_font");
  }

  /*大转盘*/

  .wheel-box {
    padding-bottom: 0.22rem;
    margin: 0.48rem 0 0;
    transition: transform 3s ease;
    position: absolute;
    top: 22vh;
    background: url("../../../assets/wheel/shadow_circle@3x.png") no-repeat bottom center;
    background-size: 3.2rem 0.6rem;
  }

  .lucky-bottom {
    width: 100%;
    margin-top: 0.58rem;
    position: absolute;
    bottom: 4%;

    .lucky-btn {
      .wh(2.6rem, 0.96rem);
      line-height: 0.96rem;
      text-align: center;
      font-weight: 500;
      .sc(0.32rem, #9A6100);
      background: rgba(255, 231, 0, 1);
      border-radius: 0.16rem;
      box-shadow: 0.12rem 0.14rem 0.04rem #B55D00
    }

    .look {
      position: absolute;
      top: 0;
      right: 0.24rem;
      line-height: 0.96rem;
      .sc(0.28rem, @color-white);

      .triangle {
        margin-left: 0.14rem;
        position: relative;
        width: 0;
        height: 0;
        border-top: 0.08rem solid transparent;
        border-bottom: 0.08rem solid transparent;
        border-left: 0.08rem solid @color-white;
      }
    }
  }

  /*剩余抽奖次数*/

  .times {
    .sc(0.28rem, @color-white);
    position: absolute;
    top: -0.8rem;
  }

  .sea-times {
    top: -0.35rem;
  }
  .f28{
    .sc(0.28rem, @color-white);
  }
  .f36 {
    .sc(0.36rem, @color-white)
  }

  .f500 {
    font-weight: 500;
  }

  /*弹窗*/

  .toast-mask {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  /*中奖弹窗*/

  .toast {
    .wh(4.7rem, 6.58rem);
    background: @color-white;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    border-radius: 0.2rem;
    .bis("../../../assets/wheel/img_farm_bg");

    .toast-container {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 0.88rem;
      padding-bottom: 0.26rem;

      .toast-circle {
        .wh(3.44rem, 3.44rem);
        border-radius: 50%;
        border: 0.08rem solid #FF6600;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .toast-bottom {
        .sc(0.28rem, #FF6600);
        line-height: 0.4rem;
        text-align: center;
        width: 100%;

        .toast-btn {
          .wh(2.2rem, 0.68rem);
          line-height: 0.68rem;
          text-align: center;
          .sc(0.28rem, @color-white);
          background: rgba(254, 85, 46, 1);
          border-radius: 0.34rem;
          margin-top: 0.16rem;

        }
      }

    }
  }

  /*游戏规则弹窗*/

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    backface-visibility: hidden;

    .alert-content {
      .cl;
    }
  }

  .rules-alert {
    .alert-content {
      top: 0.7rem;

      .rules-container {
        .wh(6.7rem, 8.86rem);
        margin-bottom: 0.4rem;
        background-color: #fff;
        border-radius: 0.08rem;
        border: 0.04rem solid #FE552E;
        overflow: hidden;

        .rules-screen {
           .wh(6.5rem, 8.66rem);
            padding: 0.3rem 0.2rem;
            background-color: #fff;
            border-radius: 0.08rem;
            border: 0.04rem solid #FE552E;
            overflow: auto;
            .rules-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              // .wh(6.5rem, 8.66rem);
              // padding: 0.3rem 0.2rem;
              // background-color: #fff;
              // border-radius: 0.08rem;
              // border: 0.04rem solid #FE552E;
              // overflow: hidden;

              .active-time {
                width: 100%;
                .sc(0.26rem, @color-grey);
                line-height: 0.44rem;
                margin-bottom: 0.3rem;
              }

              .active-explain {
                margin: 0;
              }

              .top-title {
                display: flex;
                align-items: center;
                margin-bottom: 0.76rem;

                .dot {
                  display: inline-block;
                  .wh(0.08rem, 0.08rem);
                  background-color: @color-light-grey;
                  border-radius: 50%;
                }

                .txt {
                  margin: 0 0.2rem;
                  .sc(@fontsize-medium, @color-light-grey);
                  line-height: 0.44rem;
                  letter-spacing: -0.02rem;
                }
              }

              .rules-title {
                margin-bottom: 0.6rem;
                .sc(@fontsize-large-x, #FE552E);
                line-height: 0.5rem;
                letter-spacing: 0.01rem;
                font-weight: bold;
              }

              .rules-content {
                width: 100%;
                max-height: 60%;
                .sc(0.26rem, @color-grey);
                line-height: 0.44rem;
                word-wrap: break-word;
                word-break: break-all;
                overflow: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
              }
            }
        }
      }

      .close-btn {
        .wh(0.68rem, 0.68rem);
        margin: 0 auto;
        .bis("../../../assets/icon_denglu_guanbi");
      }
    }
  }

  .zoom-enter-active {
    animation-name: zoom-in;
    animation-duration: 1s;
  }

  .zoom-leave-active {
    animation-name: zoom-out;
    animation-duration: 1s;
  }

  @keyframes zoom-in {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes zoom-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    100% {
      opacity: 0;
    }
  }

}

</style>
