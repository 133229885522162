var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "_big-wheel" } }, [
    _c("canvas", {
      ref: "wheelCanvas",
      staticClass: "_big-wheel-canvas",
      style: `transform: rotate(${
        _vm.canvasAngle + _vm.targetAngle
      }deg);transition-duration: ${_vm.transitionDuration}s;`,
      attrs: { width: "360", height: "360" },
    }),
    _c("img", {
      staticClass: "_big-wheel-go-img",
      class: _vm.goClassName,
      attrs: { src: _vm.goBtnImg },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm._onGo.apply(null, arguments)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }